import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCaseFileStore = defineStore('caseFileStore', () => {
  const caseFiles = ref([]);
  const caseData = ref(null);

  return {
    caseFiles,
    caseData
  };
});
